import config from "./config.json";
import "./App.css";
import { createContext, useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./Navbar/NavBar";
import LoginPage from "./Pages/Login/LoginPage";
import Dashboard from "./Pages/DashBoard/Dashboard";
import addInterceptors from "./Middlewares/AxiosInterceptors";
export const ENV_VARS = config[process.env.NODE_ENV];
export const UserContext = createContext();

function App() {
  axios.defaults.baseURL = ENV_VARS.REACT_APP_BASEURL;
  const [user, setuser] = useState(null);
  let x = localStorage.getItem("userdata");
  useEffect(() => {
    addInterceptors(setuser);
  }, []);
  useEffect(() => {
    if (!user) {
      if (x) {
        setuser(JSON.parse(x));
      }
    }
  }, [user, x]);
  return (
    <div className="App">
      <UserContext.Provider value={[user, setuser]}>
        {user ? <Dashboard /> : <LoginPage />}
      </UserContext.Provider>
    </div>
  );
}

export default App;
