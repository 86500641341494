import axios from "axios";
import jwtDecode from "jwt-decode";
import { UserContext } from "../App";
import { AxiosInstance } from "./AxiosInstance";

export default function addInterceptors(setUser) {
  AxiosInstance.interceptors.request.use(
    async (req) => {
      let token = JSON.parse(localStorage.getItem("userdata")).token;
      if (token) {
        let decoded = jwtDecode(token);
        console.log("token found");
        if (decoded.exp < Date.now() / 1000) {
          console.log("token expired");
          let newRes = await axios.post(
            "auth/refresh",
            {},
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
          localStorage.setItem("userdata", JSON.stringify(newRes.data.payload));
          setUser(newRes.data.payload);
          token = newRes.data.payload.token;
          req.headers["x-access-token"] = token;

          return req;
        } else {
          req.headers["x-access-token"] = token;
          return req;
        }
      } else {
        console.log("returning 2");
        return req;
      }
    },
    async (error) => {
      return Promise.reject(error);
    }
  );

  AxiosInstance.interceptors.response.use(
    async (res) => {
      if (res.data.status === "FAIL") {
        console.log("got fail");
        if (res.data.message === "EXPIRED_TOKEN") {
          const token = JSON.parse(localStorage.getItem("userdata")).token;
          console.log("token is expired");
          let newRes = await axios.post(
            "auth/refresh",
            {},
            {
              headers: {
                "x-access-token": token,
              },
            }
          );
          localStorage.setItem("userdata", JSON.stringify(newRes.data.payload));
          setUser(JSON.stringify(newRes.data.payload));

          return res;
        }
      } else {
        return res;
      }
    },
    async (res) => {
      console.log("error");
      console.log(res);
    }
  );
}
