import axios from "axios";
import React, { useContext, useState } from "react";
import { UserContext } from "../../App";
import jwt from "jwt-decode";
import { AxiosInstance } from "../../Middlewares/AxiosInstance";

export default function TestingZone() {
  const [user, setuser] = useContext(UserContext);
  const [msg, setmsg] = useState("");
  const [msg2, setmsg2] = useState("");
  return (
    <div>
      <button
        onClick={() => {
          test();
        }}
        className="btn btn-primary"
      >
        Hello
      </button>
      <p>{msg}</p>
      <p>{msg2}</p>
    </div>
  );
  async function test() {
    let res = await AxiosInstance.get("/test", {});

    if (typeof res?.data === "object") {
      setmsg(JSON.stringify(res.data));
    } else {
      setmsg(JSON.stringify(res));
    }
    let decoded = jwt(user.token);
    setmsg2(JSON.stringify(decoded));
  }
}
