import axios from "axios";
import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { UserContext } from "../../App";

export default function LoginPage() {
  const [loading, setloading] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [user, setuser] = useContext(UserContext);
  return (
    <Container
      fluid
      className="bg-dark"
      style={{
        height: "100vh",
      }}
    >
      <Row
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Col xs={12} md={6} lg={4} className="mx-auto text-start">
          <Form onSubmit={login} className="px-5 py-2 text-light rounded-4">
            <div className="text-center">
              <img
                alt="logomadripple"
                className="my-2"
                src={require("../../Images/logoicon.png")}
                style={{ width: "30%" }}
              />
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Username" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <div className="text-center mt-5">
              <Button
                disabled={loading}
                variant="light"
                type="submit"
                onClick={() => {}}
              >
                ENTER
              </Button>
              <p className="text-danger mt-4">{errormessage}</p>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );

  async function login(e) {
    e.preventDefault();
    let username = e.target[0].value;
    let password = e.target[1].value;
    setloading(true);
    let temp = await axios.post("/auth/login ", {
      username,
      password,
    });
    setloading(false);
    console.log(temp);
    if (temp.data.status === "Fail") {
      setErrormessage(temp.data.message);
      console.log("error");
      return;
    }
    setuser(temp.data.payload);
    localStorage.setItem("userdata", JSON.stringify(temp.data.payload));
  }
}
