import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import * as Icon from "react-bootstrap-icons";
import { UserContext } from "../App";

export default function NavBar() {
  const [user, setuser] = useContext(UserContext);
  return (
    <Navbar className="text-light" bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">MadRipple</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">
              <></>
            </Nav.Link>
          </Nav>
          <Nav>
            <span className="p-2 text-light">Hello, {user.user.username}!</span>
            <NavDropdown title={<Icon.GearFill color="white" />} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => {}}>Profile</NavDropdown.Item>
              <NavDropdown.Item className="text-danger" onClick={() => {
                Logout();
              }}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

  function Logout()
  {
    localStorage.removeItem("userdata");
    setuser(null);
  }
}
