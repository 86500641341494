import React, { useContext } from "react";
import { UserContext } from "../../App";
import { Col, Container, Nav, NavItem, Row, Tab, Tabs } from "react-bootstrap";
import NavBar from "../../Navbar/NavBar";
import "./Dashboard.scss"
import TestingZone from "../TestingZone/TestingZone";

export default function Dashboard() {
  const [user, setUser] = useContext(UserContext);

  return (
    <div>
      <NavBar />
      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Container fluid>
          <Row className="p-3">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="1">Main</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2">Tools</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="3">Experimental</Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <Row>
            <Tab.Content>
              <Tab.Pane eventKey="1">
                This is the main
              </Tab.Pane>
              <Tab.Pane eventKey="2">The Tools</Tab.Pane>
              <Tab.Pane eventKey="3">
                <TestingZone />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Container>
      </Tab.Container>
    </div>
  );
}
