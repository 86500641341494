import config from "../config.json";
import axios from "axios";
const ENV_VARS = config[process.env.NODE_ENV];

export const AxiosInstance = axios.create({
  baseURL: ENV_VARS.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});
